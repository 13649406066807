import React, { useEffect } from 'react';
import './AboutSection.scss';
import './AboutSectionMobile.scss';
import './AboutSectionTablette.scss';
import ImageGallery from '../ImageGallery/ImageGallery';
import StackedGallery from '../StackedGallery/StackedGallery';
import { useSpring, animated } from 'react-spring';

const AboutSection = () => {
    const [styles, api] = useSpring(() => ({ opacity: 0 }));

    useEffect(() => {
        api.start({ opacity: 1 });
    }, [api]);

    return (
        <section className="about-section" aria-labelledby="main-heading">
            <animated.div style={styles} className="content">
            <picture>
    <source 
        sizes="(max-width: 1400px) 100vw, 1400px" 
        srcSet={`
            ${process.env.PUBLIC_URL}/img/maison_d5fcvu_c_scale,w_50.webp 20w,
            ${process.env.PUBLIC_URL}/img/maison_d5fcvu_c_scale,w_200.webp
        `}
        type="image/webp" />
    <img
        src={`${process.env.PUBLIC_URL}/img/maison_pj34a3_c_scale,w_1400.webp`}
        alt="Vue extérieure du Restaurant Le Cro-Magnon"
        className="restaurant-logo" 
        height={110} 
        width={200}
        />
</picture>

 <h1 id="main-heading">Restaurant</h1>
                <h2>Le Cro-Magnon</h2>

                <div className='blocLogoNoir'>
                <div className="icon-container">
    <a href="https://www.facebook.com/cromagnon.orcieres" target="_blank" rel="noopener noreferrer" aria-label="Facebook du Restaurant Le Cro-Magnon">
        <img src={`${process.env.PUBLIC_URL}/img/facebookwhite.png`}  alt="Page Facebook du Restaurant Le Cro-Magnon" />
        <span className="icon-title">Facebook</span>
    </a>
</div>
                    
                    <div className="icon-container">
                        <a href="https://www.tripadvisor.fr/Restaurant_Review-g635590-d1338600-Reviews-Le_Cro_Magnon-Orcieres_Hautes_Alpes_Provence_Alpes_Cote_d_Azur.html?m=19905" target="_blank" rel="noopener noreferrer" aria-label="TripAdvisor du Restaurant Le Cro-Magnon">
                            <img src={`${process.env.PUBLIC_URL}/img/trip.png`}  alt="Page TripAdvisor du Restaurant Le Cro-Magnon" />
                            <span className="icon-title">TripAdvisor</span>
                        </a>
                    </div>
                    <div className="icon-container">
                        <a href="#localisation-le-cro-magnon" aria-label="Localisation du Restaurant Le Cro-Magnon">
                            <img src={`${process.env.PUBLIC_URL}/img/location.png`}  alt="Icône de localisation" className='locationIcon' />
                            <span className="icon-title">Localisation</span>
                        </a>
                    </div>
                    <div className="icon-container">
                        <a href="#la-carte" aria-label="Menu du Restaurant Le Cro-Magnon">
                            <img src={`${process.env.PUBLIC_URL}/img/menu.png`}  alt="Icône de menu" />
                            <span className="icon-title">Menu</span>
                        </a>
                    </div>
                    <div className="icon-container">
                        <a href="tel:+33492557346" aria-label="Réserver par téléphone au 04 92 55 73 46">
                            <img src={`${process.env.PUBLIC_URL}/img/phone.png`} alt="Icône de téléphone" />
                            <span className="icon-title">Téléphone</span>
                        </a>
                    </div>
                </div>
                <div className='blocTextes'>
                <p className='text-long'>
    Bienvenus au <strong>restaurant Le Cro Magnon</strong> où les <strong>saveurs des Hautes-Alpes</strong> rencontrent le charme et la simplicité d'une <strong>ambiance conviviale et authentique</strong> autour d’un feu de cheminée. 
    Situé à <strong>Orcières Merlette</strong>, dans une ancienne bergerie, notre restaurant est une <strong>escale gourmande</strong> pour les amoureux de la <strong>montagne</strong> et de la bonne table. 
    Laissez-vous séduire par notre <strong>cuisine aux saveurs montagnardes</strong> (Plats gratinés, Fondue, Raclette, préparée à partir <strong>d'ingrédients locaux</strong> et servie dans un <strong>cadre chaleureux</strong>. 
    Venez profiter en famille ou entre amis d’un moment de plaisir et de partage.
</p>

                <p className="text-short">
    Découvrez Le Cro Magnon à Orcières, un havre de saveurs alpines. Savourez nos plats locaux dans une ambiance chaleureuse et authentique, parfaits pour les amateurs de montagne et de bonne cuisine.
</p>
</div>

                <div className="gallery-desktop" role="complementary">
                    <ImageGallery />
                </div>
                <div className="gallery-mobile" role="complementary">
                    <StackedGallery />
                </div>

                <div className='blocRS'>
                    <div className="logoRS">
                        {/* Contenu potentiel pour 'blocRS' et 'logoRS' */}
                    </div>
                </div>
            </animated.div>
        </section>
    );
}

export default AboutSection;

const Modal = ({ onClose, children }) => {
    return (
        <div className="modal-backdrop" role="dialog" aria-modal="true">
            <div className="modal" tabIndex="-1">
                {children}
                <button onClick={onClose} aria-label="Fermer la fenêtre modale">Fermer</button>
            </div>
        </div>
    );
};

export default Modal;

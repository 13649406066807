import React from "react";
import "./HeaderSection.scss";
import "./HeaderSectionTablette.scss";
import "./HeaderSectionMobile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const HeaderSection = () => {
  return (
    <section className="header-section">
      <div className="bandeau">
        <div className="blocTripAvis">
        <div className="google-card">
    <a href="https://g.co/kgs/8tVsDZC" target="_blank" rel="noopener noreferrer">
      <div className="google-card-content">
        <img
          src="./img/google.png"
          alt="Logo Google"
          className="google-logo"
        />
        <p>Vérifiez nos horaires</p>
      </div>
    </a>
  </div>
        </div>
        <div className="blocSocial">
          <div className="icon-containe-headerr">
            <a
              href="https://www.tripadvisor.fr/Restaurant_Review-g635590-d1338600-Reviews-Le_Cro_Magnon-Orcieres_Hautes_Alpes_Provence_Alpes_Cote_d_Azur.html"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TripAdvisor du Restaurant Le Cro-Magnon"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/tripwhite.svg`}
                height={30}
                width={30}
                alt="Page TripAdvisor du Restaurant Le Cro-Magnon"
                loading="lazy"
                className="tripwhite"
              />
            </a>
          </div>
          <div className="icon-container-header">
            <a
              href="https://www.facebook.com/cromagnon.orcieres"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook du Restaurant Le Cro-Magnon"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/facebookwhite.png`}
                height={30}
                width={30}
                alt="Page Facebook du Restaurant Le Cro-Magnon"
                loading="lazy"
                className="facebookwhite"
              />
            </a>
            
            <a
              href="https://www.facebook.com/cromagnon.orcieres"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook du Restaurant Le Cro-Magnon"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/insta.png`}
                height={30}
                width={30}
                alt="Page Instagram du Restaurant Le Cro-Magnon"
                loading="lazy"
                className="instawhite"
              />
            </a>
          </div>{" "}
        </div>
      </div>

      <div className="blocCentral">
        <div className="content2">
          <div className="blocBouton">
            <img
              src={`${process.env.PUBLIC_URL}/img/logo.webp`}
              width={460}
              height={480}
              alt="Logo du restaurant Cro Magnon"
              className="logo"
              loading="lazy"
            />{" "}
            <h3>Réservez votre table !</h3>
            <a href="tel:0492557346" className="boutonResaDesktop">
              04 92 55 73 46
              <FontAwesomeIcon icon={faPhone} className="faPhoneIcon" />{" "}
            </a>
            <a href="tel:0492557346" className="boutonResa">
              04 92 55 73 46 <FontAwesomeIcon icon={faPhone} />{" "}
            </a>
            <h4>Réservation conseillée et uniquement par téléphone !</h4>
          </div>
          <div className="blocDroite">
            <div className="main-text-and-logo">
              <h2>
                L'authenticité <br /> d'Orcières, servie sur
                <br />
                un plateau préhistorique
              </h2>

              <p className="heures">
              <p>Nos <strong>horaires d'ouverture</strong> sont adaptés à la <strong>saison hivernale<br/></strong><strong>vérifiez-les en nous appelant</strong></p>
              </p>
              <div className="bandeauMobile">
                <div className="blocTripAvis">
                <div className="google-card">
    <a href="https://g.co/kgs/8tVsDZC" target="_blank" rel="noopener noreferrer">
      <div className="google-card-content">
        <img
          src="./img/google.png"
          alt="Logo Google"
          className="google-logo-mobile"
        />
        <p>Vérifiez nos horaires</p>
      </div>
    </a>
  </div>
                </div>
                <div className="blocSocial2">
                  <a
                    href="https://www.tripadvisor.fr/Restaurant_Review-g635590-d1338600-Reviews-Le_Cro_Magnon-Orcieres_Hautes_Alpes_Provence_Alpes_Cote_d_Azur.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="TripAdvisor du Restaurant Le Cro-Magnon"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/tripwhite.svg`}
                      alt="Logo TripAdvisor"
                      loading="lazy"
                    ></img>
                  </a>

                  <a
                    href="https://www.instagram.com/lecromagnon05?igsh=eXdoNjAxbGpqYmEw"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="TripAdvisor du Restaurant Le Cro-Magnon"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/insta.png`}
                      alt="Logo Instagram"
                      loading="lazy"
                    ></img>
                  </a>

                  <a
                    href="https://www.facebook.com/cromagnon.orcieres"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook du Restaurant Le Cro-Magnon"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/facebookwhite.svg`}
                      alt="Logo Facebook"
                      loading="lazy"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="landscapeMobilee">
        <img
          src={`${process.env.PUBLIC_URL}/img/logo.webp`}
          alt="Logo du restaurant Cro Magnon"
          className="logoLandscape"
          loading="lazy"
        />
        <a href="tel:0492557346" className="boutonResaLandscape">
          04 92 55 73 46 <FontAwesomeIcon icon={faPhone} />{" "}
        </a>
        <h2>
          L'authenticité d'Orcières, servie <br />
          sur un plateau préhistorique
        </h2>
        <p>
          <strong> 19h à 22h </strong> Tous les jours et{" "}
          <strong>12h à 14h</strong> (sauf Lundi et Mardi)
        </p>
      </section>
    </section>
  );
};

export default HeaderSection;

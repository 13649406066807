import React, { useState, useEffect } from 'react';
import './FloatingNavbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const FloatingNavbar = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <nav className={`floating-navbar ${isVisible ? 'is-visible' : ''}`} aria-label="Menu principal">
            <img 
                src={`${process.env.PUBLIC_URL}/img/logo.webp`} 
                alt="Logo du restaurant Cro Magnon" 
                className="logo" 
                onClick={scrollToTop}
                role="button"
                tabIndex={0}
                onKeyPress={scrollToTop}
                height={43}
                width={43}
            />

            <ul>
                <li><a href="#restaurant">Restaurant</a></li>
                <li><a href="#la-carte">La carte</a></li>
                <li><a href="#acces">Accès</a></li>
                <li id='tel'>
                    <a href="tel:+33492557346" aria-label="Réserver par téléphone au 04 92 55 73 46">
                        <FontAwesomeIcon icon={faPhone} />
                        <span className="reserve-text"> Réserver</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default FloatingNavbar;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './App.scss';
import HeaderSection from './components/HeaderSection/HeaderSection';
import AboutSection from './components/AboutSection/AboutSection';
import AccessSection from './components/AccessSection/AccessSection';
import Footer from './components/Footer/Footer';
import FloatingNavbar from './components/FloatingNavbar/FloatingNavbar';
import MenuPage from './components/MenuPage/MenuPage';
import TripSection from './components/TripSection/TripSection';
import ErrorPage from './components/404/error';

function App() {
  // Options pour useInView
  const viewOptions = {
    triggerOnce: true,
    threshold: 0.1
  };

  // Utilisez useInView pour chaque section
  const [aboutRef, aboutInView] = useInView(viewOptions);
  const [menuRef, menuInView] = useInView(viewOptions);
  const [accessRef, accessInView] = useInView(viewOptions);
  const [tripRef, tripInView] = useInView(viewOptions);

  // Variants pour les animations de Framer Motion
  const sectionVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0.8 }
  };

  return (
    <Router>
      <div className="App">
        <FloatingNavbar />
        <Routes>
          <Route path="/" element={
            <>
              <HeaderSection />
              <motion.div
                id="restaurant"
                ref={aboutRef}
                initial="hidden"
                animate={aboutInView ? 'visible' : 'hidden'}
                variants={sectionVariants}
              >
                <AboutSection />
              </motion.div>
              <motion.div
                id="la-carte"
                ref={menuRef}
                initial="hidden"
                animate={menuInView ? 'visible' : 'hidden'}
                variants={sectionVariants}
              >
                <MenuPage />
              </motion.div>
              <motion.div
                id="acces"
                ref={accessRef}
                initial="hidden"
                animate={accessInView ? 'visible' : 'hidden'}
                variants={sectionVariants}
              >
                <AccessSection />
              </motion.div>
              <motion.div
                ref={tripRef}
                initial="hidden"
                animate={tripInView ? 'visible' : 'hidden'}
                variants={sectionVariants}
              >
                <TripSection />
              </motion.div>
            </>
          } />
          {/* Ajoutez d'autres routes ici selon vos besoins */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal'; // Assurez-vous de créer ce composant
import './AccessSection.scss';
import './AccessSectionTablette.scss';
import './AccessSectionMobile.scss';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const AccessSection = () => {
    const longText = "Au cœur des Alpes, notre charmant restaurant Le Cro-Magnon vous attend. Laissez-vous guider par notre plan, votre clé pour nous trouver dans les dédales montagneux d'Orcières. Oubliez un peu la technologie et suivez nos indications pour une arrivée sans encombre, que vous soyez à pieds ou en voiture. Notre plan vous emmènera directement à notre porte, où des saveurs délicieuses et un accueil chaleureux vous attendent. Nous sommes impatients de partager avec vous un moment convivial et gourmand chez Le Cro-Magnon !";
    const shortText = "Le Cro-Magnon, niché au cœur des Alpes à Orcières vous attends. Notre plan vous guidera facilement à notre porte, que ce soit à pieds ou en voiture. Venez savourer nos délices culinaires et profitez d'un accueil chaleureux.";
    const [displayText, setDisplayText] = useState(longText);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setDisplayText(shortText);
            } else {
                setDisplayText(longText);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isModalOpen]);

    return (
        <section className="access-section" aria-labelledby="access-title">
            <img loading="lazy" height={102} width={140} className='pieds' alt='Empreinte de pas symbolisant le chemin au restaurant Le Cro-Magnon' src={`${process.env.PUBLIC_URL}/img/pieds.webp`} />
            <h2 id="access-title" className='titreVenir'>Comment venir ?</h2>
            <p className='adresse'>Les Hauts de Merlette <strong>Orcières 05170</strong></p>
            <p className='TexteMobile'>{shortText}</p>
            <div className="content-wrapper">
                <div className="left-column">
                    <div className="hand-drawn-map" onClick={openModal} role="button" aria-label="Voir le plan dessiné à la main en grand format">
                        <img src={`${process.env.PUBLIC_URL}/img/planmini.webp`} height={365} width={373} alt="Plan dessiné à la main du restaurant Le Cro-Magnon" />
                    </div>
                    <div className="path-indicators pathMobile">
                        <div className="path-indicator">
                            <div className="circle walking"></div>
                            <span>À pieds</span>
                        </div>
                        <div className="path-indicator">
                            <div className="circle driving"></div>
                            <span>En voiture</span>
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <div className='text-section'>
                        <h3>Trouvez Votre Chemin vers <br /><strong>Le Cro-Magnon</strong></h3>
                        <p>{displayText}</p>
                        <div className="path-indicators pathDesktop">
                            <div className="path-indicator">
                                <div className="circle walking"></div>
                                <span><strong>À pieds</strong> (Par les escaliers en face du Palais des sports)</span>
                            </div>
                            <div className="path-indicator">
                                <div className="circle driving"></div>
                                <span><strong>En voiture</strong></span>
                            </div>
                        </div>
                        <div className="google-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2836.154860353339!2d6.316443877586202!3d44.69603717107171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cb50ca3e5dfb73%3A0x65f9bedd175bdb30!2sLe%20Cro-Magnon!5e0!3m2!1sfr!2sfr!4v1701357459820!5m2!1sfr!2sfr"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Google Maps - Le Cro-Magnon"
                                aria-label="Carte Google Maps montrant l'emplacement du restaurant Le Cro-Magnon"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <div ref={modalRef}>
                        <TransformWrapper>
                            <TransformComponent className='test000'>
                                <img
                                    src={`${process.env.PUBLIC_URL}/img/plan.webp`}
                                    className='modal-image'
                                    alt="Plan dessiné à la main du restaurant Le Cro-Magnon en grand format"
                                    height={365}
                                    width={373}
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Modal>
            )}
        </section>
    );
}

export default AccessSection;

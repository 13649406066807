import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import './StackedGallery.scss';

Modal.setAppElement('#root');

const imagesData = [
    "/img/about/1.webp",
    "/img/about/2.webp",
    "/img/about/3.webp",
    "/img/about/4.webp",
    "/img/about/5.webp",
    "/img/about/6.webp",
    "/img/about/7.webp",
    "/img/about/8.webp",
    "/img/about/9.webp",
    "/img/about/10.webp",
    "/img/about/11.webp",
    "/img/about/12.webp",
    "/img/about/13.webp",
    "/img/about/14.webp",
    "/img/about/15.webp",
    "/img/about/16.webp",
    "/img/about/17.webp",
    "/img/about/18.webp",
    "/img/about/19.webp",
    "/img/about/20.webp",
    "/img/about/21.webp",

];

const StackedGallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const delay = 3000; // 3000ms = 3 secondes

    const navigate = useCallback((newIndex) => {
        const validIndex = newIndex < 0 ? imagesData.length - 1 : newIndex % imagesData.length;
        setCurrentIndex(validIndex);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            navigate(currentIndex + 1);
        }, delay);

        return () => clearInterval(interval);
    }, [currentIndex, navigate]);

    const openModal = (index) => {
        setCurrentIndex(index);
        setModalIsOpen(true);
    };

    return (
        <div className="stacked-gallery">
            <button className="gallery-nav left" onClick={() => navigate(currentIndex - 1)} aria-label="Précédent">
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className="images-stack">
                {imagesData.map((img, index) => (
                    <img 
                        key={index}
                        src={`${process.env.PUBLIC_URL}${img}`}
                        alt={`Galerie ${index + 1}`}
                        className={`gallery-image ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => openModal(index)}
                    />
                ))}
            </div>
            <button className="gallery-nav right" onClick={() => navigate(currentIndex + 1)} aria-label="Suivant">
                <FontAwesomeIcon icon={faArrowRight} />
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel={`Galerie image ${currentIndex + 1}`}
                className="Modal"
                overlayClassName="Overlay"
            >
                <span className="close-modal" onClick={() => setModalIsOpen(false)} aria-label="Fermer la fenêtre modale">
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                <button className="modal-nav left" onClick={() => navigate(currentIndex - 1)} aria-label="Précédent">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <img 
                    src={`${process.env.PUBLIC_URL}${imagesData[currentIndex]}`}
                    alt={`Agrandissement galerie ${currentIndex + 1}`}
                    className="modal-image"
                />
                <button className="modal-nav right" onClick={() => navigate(currentIndex + 1)} aria-label="Suivant">
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </Modal>
        </div>
    );
};

export default StackedGallery;

const updatedMenuData = [
    {
        section: "Entrées",
        iconName: "utensils",
        items: [
            { name: "Planche Apéritif", quantity: "A partager pour 2 personnes", price: "13.00€", additional: "Charcuterie et Fromages", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Œufs cocotte aux Foie gras", price: "13.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Os à Moelle à la Fleur de sel",quantity:"(env. 500 gr)", price: "13.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Panier de Tourtons du Champsaur ", quantity:"(6 coussins du petit Jésus au chèvre)", price: "11.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp`},
            { name: "Panier de Ravioles du Champsaur", quantity:"(6 quenelles brousse de chèvre et p.d.terre)", price: "11.00€",  imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Saveurs Montagnardes",
        iconName: "breadSlice",
        items: [
            { name: "Planche du Champsaur", additional:"Salade, Crudités, Charcuterie, Tourtons, Ravioles, Oreilles d’Ane, Fromage de Pays", price: "26.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Oreilles d’Ane", additional:"Gratin d’épinard, Charcuterie, Salade et Crudités", price: "23.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Ravioles du Champsaur Gratinées à la morille", price: "23.50€", additional: "servie avec Salade et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Berthoud de la Vallée d’abondance", additonal:"Fromage d’abondance mariné à l’ail et fondu au four servi avec Charcuterie, P. de Terre, Salade et Crudités", price: "23.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Reblochonnade",additionnal:"servie avec Charcuterie, Salade Verte et P. de Terre", price: "23.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Boîte Chaude des Hautes-Alpes à l’ail noir", quantity:'(220g)', price: "25.50€", additional: "Fromage de vache cuit au four, servie avec Charcuterie, Salade Verte, P. de Terre", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Poisson du jour et ses petits Légumes", price: "23.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Burgers",
        iconName: "burger",
        items: [
            { name: "Burger Le Cro-Magnon et Frites Maison", price: "24.00€", additional: "Pain artisanal, Steak haché, Sauce Morilles maison, Jambon Cru, Salade Verte, Tomate, Oignon rouge", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Burger Le Savoyard et Frites Maison", price: "23.50€", additional: "Pain artisanal, Steak haché, Sauce Reblochon maison, Poitrine fumé, Salade Verte, Tomate, Oignon rouge", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Viandes",
        iconName: "utensils",
        items: [
            { name: "Magret de canard", additional:"(origine France), Frites, Salade et Crudités", price: "28.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Pièce du boucher", additional:"(origine France), Frites et Salade / Crudités ou Légumes", price: "26.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Côte de Bœuf Maturée", quantity:"500gr", price: "36.00€", additional: "(origine France ou Allemagne) Supplément Sauce Maison : au Bleu ou Morilles 2.50€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Les Raclettes",
        iconName: "cheese",
        items: [
            { name: "Raclette Traditionnelle", price: "26.00€", additional: "Tomme de la Fromagerie du Queyras - Servie avec P.de Terre, Charcuterie, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Raclette locale du Champsaur Hautes Alpes", price: "26.50€", additional: "Ferme de La Tuilerie St-Bonnet - Servie avec P.de Terre, Charcuterie, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Raclette à l’Abondance AOP", price: "27.50€", additional: "Maison Chabert (Maître Fromager depuis 1936) Ce fromage est affiné pendant 100 jours au minimum, sur des planches d’épicéa, en cave fraiche et humide - Pâte souple et fondante de couleur ivoire à jaune pâle. Servie avec P. de Terre, Charcuterie, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Fondue Bourguignonne",
        iconName: "fondue",
        items: [
            { name: "Fondue Bourguignonne : Bœuf", price: "27.50€", additional: "Servie avec Frites Maison, sauces Maison, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue Bacchus : Bœuf", price: "27.50€", additional: "Servie avec Frites Maison, sauces Maison, Salade Verte et Crudités Le vin rouge aromatisé remplace l’huile de cuisson pour une Fondue plus digeste", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Les Fondues aux 4 et 5 Fromages",
        iconName: "cheese",
        items: [
            { name: "Fondue Savoyarde", price: "24.00€", additional: "4 Fromages, Croutons, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue « Cro-Magnon »", price: "29.00€", additional: "4 Fromages, Croutons, Salade Verte, Crudités, Charcuterie", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue Au Bleu (Ferme de La Tuilerie)", price: "26.00€", additional: "5 Fromages, Croutons, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue aux Cèpes", price: "27.50€", additional: "4 Fromages, Cèpes, Croutons, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue aux Morilles", price: "28.50€", additional: "4 Fromages, Morilles, Croutons, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Fondue Cèpes et Morilles", price: "28.50€", additional: "4 Fromages, Cèpes, Morilles, Croutons, Salade Verte et Crudités", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    },
    {
        section: "Les Cro-Mignons",
        iconName: "child",
        items: [
            { name: "Fromage à Raclette ou Fondue Savoyarde", price: "12.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Hamburger Mignon et Frites Maison", price: "12.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Steak haché et Frites maison ou Légumes du jour", price: "12.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Aiguillettes de Poulet panées et Frites Maison", price: "12.00€", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` },
            { name: "Menu Le Cro-Mignon", price: "14.90€", additional: "Boisson au Choix (Sirop, Limonade, Jus de fruits ou Sodas)\nSteak haché ou Hamburger Mignon ou Aiguillettes de Poulet panées\nFrites Maison ou Légumes du jour\nDessert\nFromage Blanc sucre ou miel, Glace Haribo ou Rocket aux Fruits\nCoupe 2 boules parfum au choix", imageUrl: `${process.env.PUBLIC_URL}/img/plats/base.webp` }
        ]
    }
];

export default updatedMenuData;

import React from 'react';
import './Footer.scss'; 
import './FooterResponsive.scss'; 

function Footer() {
  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-content">
        <div className='bloc0'>
          <img src={`${process.env.PUBLIC_URL}/img/logo.webp`} height={1318} width={1375} alt="Logo du restaurant Le Cro Magnon" className="footer-logo" />
        </div>
        <div className='bloc1'>
          <div className="reservation">
            <span className="reservation-text">Réservez votre table !</span>
            <span className='adress'>Les Hauts de Merlettes, 05170 Orcières</span>
            <a className="phone-number" href="tel:+33492557346" aria-label="Numéro de téléphone pour réservation 04 92 55 73 46">04 92 55 73 46</a>
          </div>
        </div>
        <div className='bloc2'>
          <nav aria-label="Plan du site dans le pied de page">
            <h2 className='titrePlan'>Plan du site</h2>
            <ul className='menuFooter'>
            <li><a href="/">Accueil</a></li>
              <li><a href="/#la-carte">Menu</a></li>
              <li><a href="/#restaurant">Restaurant</a></li>
              <li><a href="/#acces">Accès</a></li>
                          </ul>
          </nav>
          <div className="social-media">
            <a href="https://www.tripadvisor.fr/Restaurant_Review-g635590-d1338600-Reviews-Le_Cro_Magnon-Orcieres_Hautes_Alpes_Provence_Alpes_Cote_d_Azur.html" aria-label="Page TripAdvisor du restaurant">
              <img src={`${process.env.PUBLIC_URL}/img/tripwhite.svg`} height={30} width={30} alt="Logo Tripadvisor" />
            </a>
            <a href="https://www.facebook.com/cromagnon.orcieres" aria-label="Page Facebook du restaurant">
              <img src={`${process.env.PUBLIC_URL}/img/facebookwhite.svg`} height={30} width={30} alt="Logo Facebook" />
            </a>
            <a href="https://www.instagram.com/lecromagnon05?igsh=eXdoNjAxbGpqYmEw" aria-label="Page Instagram du restaurant">
              <img src={`${process.env.PUBLIC_URL}/img/insta.png`} height={30} width={30} alt="Logo Instagram" />
            </a>
          </div>
        </div>
      </div>
      <span className='droits'>Tous droits réservés PAÏTAWEB</span>
    </footer>
  );
}


export default Footer;

import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import './ImageGallery.scss';

const ImageGallery = () => {
    // Importez les images grandes et petites depuis les dossiers appropriés
    const largeImageSources = Array.from({ length: 21 }, (_, i) =>
        `${process.env.PUBLIC_URL}/img/about/large/${i + 1}_large.webp`
    );

    const smallImageSources = Array.from({ length: 21 }, (_, i) =>
        `${process.env.PUBLIC_URL}/img/about/small/${i + 1}_small.webp`
    );

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openModal = (index) => {
        setSelectedImageIndex(index);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
        setModalIsOpen(false);
    };

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    const showPreviousImage = () => {
        setSelectedImageIndex((selectedImageIndex + largeImageSources.length - 1) % largeImageSources.length);
    };

    const showNextImage = () => {
        setSelectedImageIndex((selectedImageIndex + 1) % largeImageSources.length);
    };

    return (
        <div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="gallery"
                columnClassName="gallery_column"
            >
                {smallImageSources.map((smallImgSrc, index) => (
                    <div key={index} className="gallery-item" onClick={() => openModal(index)}>
                        <img src={smallImgSrc} height={450} width={500} alt={` ${index + 1} de la galerie`} />
                    </div>
                ))}
            </Masonry>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={`Image ${selectedImageIndex + 1} de la galerie`}
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    {selectedImageIndex !== null && (
                        <img src={largeImageSources[selectedImageIndex]} alt={` Aggrandissement de ${selectedImageIndex + 1} de la galerie`} />
                    )}
                    <button className="close-button" onClick={closeModal} aria-label='Fermer la fenêtre'>×</button>
                    <button className="nav-button prev-button" onClick={showPreviousImage} aria-label='Image précédente'>←</button>
                    <button className="nav-button next-button" onClick={showNextImage} aria-label='Image suivante'>→</button>
                </div>
            </Modal>
        </div>
    );
}

export default ImageGallery;

import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './TripSection.scss';
import './TripSectionResponsive.scss';

Modal.setAppElement('#root'); // Assurez-vous de définir l'élément racine correctement

const SimpleSlider = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };
  
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="slider-container">
      <div className="top-content">
        <img src="/img/trip.svg" alt="Image descriptive" />
        <h2>Ce que les gens disent <br/> <strong>de notre restaurant</strong> </h2>
      </div>

      <div className="simple-slider">
        <Slider {...settings}>
          <div onClick={() => openModal("/img/avis1.webp")}><img src="/img/avis1.webp" alt="Avis 1"/></div>
          <div onClick={() => openModal("/img/avis3.webp")}><img src="/img/avis3.webp" alt="Avis 3"/></div>
          <div onClick={() => openModal("/img/avis2.webp")}><img src="/img/avis2.webp" alt="Avis 2"/></div>
        </Slider>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal-content2" // Ajoutez cette classe pour personnaliser le style de la modale
        overlayClassName="modal-overlay2" // Ajoutez cette classe pour personnaliser le style de l'overlay
      >
        <img src={selectedImage} alt="Agrandissement" />
        <button onClick={closeModal}>Fermer</button>
      </Modal>
    </div>
  );
}

export default SimpleSlider;

// ErrorPage.js
import React from 'react';
import './error.scss';

const ErrorPage = () => {
  return (
    <div className="error-page">
                <img
              src={`${process.env.PUBLIC_URL}/img/logo.webp`}
              width={460}
              height={480}
              alt="Logo du restaurant Cro Magnon"
              className="logo404"
              loading="lazy"
            />{" "}

      <h1>404</h1>
      <p>Page non trouvée. L'URL demandée n'existe pas.</p>
    </div>
  );
};

export default ErrorPage;
